import React from 'react'
import { Heading } from 'src/components/Typography'

export default function NotFoundPage(): JSX.Element {
  return (
    <Heading $preset="h1" as="h2">
      404: Page Not Found
    </Heading>
  )
}
